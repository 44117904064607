import React, { useEffect, useState } from 'react';

const Timer = ({ initialDateTime }) => {
    const [time, setTime] = useState(0);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        const startTime = new Date(initialDateTime).getTime();
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const elapsed = Math.floor((now - startTime) / 1000);
            setTime(elapsed);
        }, 1000);

        return () => clearInterval(interval);
    }, [initialDateTime]);

    return (
        <div>
            <p>{formatTime(time)}</p>
        </div>
    );
};

export default Timer;
