import React, { useEffect,useState } from 'react';

function Community() {
    const [token, setToken] = useState();
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            setToken(tokenJson.token);
            fetchGroups();
        } else {
            setToken('');
        }
    }, []);

    const fetchGroups = async () => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Groups`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setGroups(data);
        } catch (error) {
            console.error('Error fetching Groups:', error);
        }
    };

    return (
        <>
            <div>
                <div className="row mt-5 pt-5 mb-3">
                    <div className="col align-self-center">
                        <h6 className="title text-center">PACXOS COMMUNITY</h6>
                        <p className="text-secondary small text-center">Connect with your peers</p>
                    </div>
                    <div className="col-12 text-center mt-3">
                        <div className='row'>
                            <a href='https://x.com/Pacxos_Care' className='col-4 col-md-4 mb-4'>
                                <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                    <i className="bi bi-twitter size-24" />
                                </div>
                                <p className="mb-1 small">Twitter-X</p>
                                {/* <p className="mb-0 text-secondary size-10">200 Followers</p> */}
                            </a>
                            <a href='https://www.facebook.com/Pacxos' className='col-4 col-md-4 mb-4'>
                                <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                    <i className="bi bi-facebook size-24" />
                                </div>
                                <p className="mb-1 small">Facebook</p>
                                {/* <p className="mb-0 text-secondary size-10">200 Followers</p> */}
                            </a>
                            <a href='https://t.me/pacxos' className='col-4 col-md-4 mb-4'>
                                <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                    <i className="bi bi-telegram size-24" />
                                </div>
                                <p className="mb-1 small">Telegram</p>
                                {/* <p className="mb-0 text-secondary size-10">200 Members</p> */}
                            </a>
                            <a href='https://www.tiktok.com/@pacxos?is_from_webapp=1&sender_device=pc' className='col-4 col-md-4 mb-4'>
                                <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-tiktok s-ze-24" viewBox="0 0 16 16">
                                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                                    </svg>
                                </div>
                                <p className="mb-1 small">TikTok</p>
                                {/* <p className="mb-0 text-secondary size-10">200 Followers</p> */}
                            </a>
                            {/* <div className='col-md-4'>
                                <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                    <i className="bi bi-tiktok size-24" />
                                </div>
                                <p className="mb-1 small">TikTok</p>
                                <p className="mb-0 text-secondary size-10">200</p>
                            </div> */}
                            <a href='https://whatsapp.com/channel/0029VainAxx8PgsAqM5JYC02' className='col-4 col-md-4'>
                                <div className="avatar avatar-70 bg-white text-color-theme rounded-18 mb-2 shadow-sm">
                                    <i className="bi bi-whatsapp size-24" />
                                </div>
                                <p className="mb-1 small">Whatsapp</p>
                                {/* <p className="mb-0 text-secondary size-10">200 Members</p> */}
                            </a>
                        </div>

                        <small>Community groups & channels. Join your peers.</small>
                        <div className='row'>
                            {groups.map((group) => (
                                <div key={group.id} className='col-12 col-md-6'>
                                    <a href={group.link} className="card mb-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-auto position-relative">
                                                    {group.type==1?///WHATSAPP
                                                        <div className="avatar avatar-70 bg-white text-color-theme rounded-18 mb-2 shadow-sm">
                                                            <i className="bi bi-whatsapp size-24" />
                                                        </div>
                                                    :
                                                        <>
                                                            {group.type==2?///FACEBOOK
                                                                <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                                                    <i className="bi bi-facebook size-24" />
                                                                </div>
                                                            :
                                                                <>
                                                                    {group.type==3?///TELEGRAM
                                                                        <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                                                            <i className="bi bi-telegram size-24" />
                                                                        </div>
                                                                    :
                                                                        <div className="icons text-secondary">
                                                                            <i className="bi bi-people size-24" />
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div className="col position-relative">
                                                    <h4 className='m-0'>{group.name}</h4>
                                                    <em>{group.description}</em>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default Community;