import React, { useEffect,useState } from 'react';
import { getAuthDataFromLocalStorage } from "./Data";
import { parseISO, isBefore } from 'date-fns';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
////import 'bootstrap/dist/css/bootstrap.min.css';
///https://api.pacxos.com/v1

import Header from './components/Sections/Header';
import Footer from './components/Sections/Footer';
import Navigation from './components/Sections/Navigation';
import Dashboard from './components/Account/Dashboard';
import Profile from './components/Account/Profile';
import Community from './components/Account/Community';
import Support from './components/Account/Support';
import History from './components/Account/History';
import Live from './components/Account/Live';
import Wallet from './components/Account/Wallet';
import Games from './components/Play/Games';
import Game from './components/Play/Game';
import Join from './components/Play/Join';
import Match from './components/Play/Match';
import End from './components/Play/End';
import Bet from './components/Play/Bet';
import Arena from './components/Arena/Directory';

import Auth from './components/Account/Auth';

import { Route, Routes } from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the default mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setShowInstallButton(true);
    };

    const jsonData = localStorage.getItem('pacxosData');

    if (jsonData) {
      const tokenJson = JSON.parse(jsonData);
      if(tokenJson.expiryDate){
        ///console.log("Session iishe:"+ tokenJson.expiryDate);

        const expiry = parseISO(tokenJson.expiryDate);
        const now = new Date();

        if (isBefore(expiry, now)) {
          setIsLoggedIn(false);
        } else {
          setIsLoggedIn(true);
        }
      }else{
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    window.addEventListener('beforeunload', (e) => {
      if (!isOnline) {
        e.preventDefault();
        e.returnValue = ''; // Display a confirmation dialog
      }
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
      window.removeEventListener('beforeunload', (e) => {
        if (!isOnline) {
          e.preventDefault();
          e.returnValue = '';
        }
      });
    };
  }, [isOnline]);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('pacxosData');
    localStorage.removeItem('refarralQrCode');
    setIsLoggedIn(false);
  };

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      // We've used the prompt, and can't use it again, throw it away
      setDeferredPrompt(null);
      setShowInstallButton(false);
    }
  };

  return (
    <div className="App body-scroll">
      <>
        {isLoggedIn ? (
          <div>
            <Header/>
              <div className="main-container container pt-100">
                <Navigation 
                  onLogout={handleLogout} 
                  showInstallButton={showInstallButton} 
                  onInstallClick={handleInstallClick} 
                  isOnline={isOnline} 
                />
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile onLogout={handleLogout} />} />
                  <Route path="/community" element={<Community />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/played" element={<History />} />
                  <Route path="/live" element={<Live />} />
                  <Route path="/wallet" element={<Wallet />} />
                  <Route path="/create" element={<Games />} />
                  <Route path="/game/:id" element={<Game />} />
                  <Route path="/join" element={<Join />} />
                  <Route path="/match/:id" element={<Match />} />
                  <Route path="/end/:id" element={<End />} />
                  <Route path="/bet" element={<Bet />} />
                  <Route path="/arena" element={<Arena />} />
                </Routes>
              </div>
            <Footer
                showInstallButton={showInstallButton} 
                onInstallClick={handleInstallClick} 
                isOnline={isOnline} 
              />
          </div>
        ) : (
          <Auth path="/" onLoginSuccess={handleLoginSuccess}/>
        )}
      </>
    </div>
  );
}

export default App;
