import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

///import Menu from './Sections/Menu';

const Header = ({  }) => {
    const [isLoading, setIsLoading] = useState(true);

    ///const location = useLocation();

    useEffect(() => {
        // Simulating loading delay
        const timeout = setTimeout(() => {
        setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timeout);
    });

    //onLogout
    // const handleLogout = () => {
    //     onLogout();
    // };

    return (
        <>
            {isLoading && (
                <div>
                    {/* loader section */}
                    <div className="container-fluid loader-wrap">
                        <div className="row h-100">
                            <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
                                <img src="https://media.pacxos.com/icon.png" height='60' alt='PACXOS' />
                                <p className="mt-4"><span className="text-secondary">PACXOS</span><br /><strong>Peer 2 Peer</strong></p>
                            </div>
                        </div>
                    </div>
                    {/* loader section ends */}
                </div>

            )}
        </>
    );
}

export default Header;