import React, { useState } from 'react';

const LoginForm = ({ onLoginSuccess, toggleViewMode }) => {
  const [loginData, setLoginData] = useState({
    country: '254',
    ///nickname: '',
    phoneNumber: '',
    password: ''
    ///ConfirmPassword: ''
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setLoggingIn(false);
    setError(null);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleForgotPassword = () => {
    toggleViewMode('password');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoggingIn(true);
    try {
        const response = await fetch(`https://api.pacxos.com/v1/api/Login`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('Failed. '+errorResponse);
          setLoggingIn(false);
          return; // Exit the function if there's an error
        } 

        const data = await response.json();
        ////console.log(data);
        ///saveAccountDataToLocalStorage(data);
        const jsonData = JSON.stringify(data);
        localStorage.setItem("pacxosData", jsonData);
        onLoginSuccess();
    } catch (error) {
        setError('Login failed. Try again later');
        ///console.error('Error:', error);
        setLoggingIn(false);
    }
  };

  return (
    <div>
        <form onSubmit={handleSubmit}>
          <h1 className="mb-4"><span className="text-secondary fw-light">Welcome</span><br />Back</h1>            
          <div className="form-floating mb-3">
              <select className="form-control" id="country" name="country" value={loginData.country} onChange={handleChange}>
                  <option value='254' selected>Kenya (+254)</option>
                  <option value='255'>Tanzania (+255)</option>
                  <option value='256'>Uganda (+256)</option>
              </select>
              <label htmlFor="country">Contry</label>
          </div>
          <div className="form-floating mb-3">
              <input type="tel" className="form-control" placeholder="Phone Number" name='phoneNumber' value={loginData.phoneNumber} onChange={handleChange} id="phoneNumber" />
              <label htmlFor="phoneNumber">Phone Number</label>
          </div>
          <div className="form-floating mb-3">
              <input type={isPasswordVisible ? 'text' : 'password'} className="form-control" placeholder="Password" name='password' value={loginData.accountPassword} onChange={handleChange} id="accountPassword" />
              <label htmlFor="accountPassword">Password</label>
              <button type="button" onClick={togglePasswordVisibility} className="btn btn-link text-muted tooltip-btn">
                  <i className={isPasswordVisible ? "bi bi-eye-slash" : "bi bi-eye"} />
              </button>
          </div>
          <a href='#!' onClick={handleForgotPassword} className='text-start text-dark mb-3'>Forgot Password? RESET.</a>
          {error && <p className="text-danger my-3">{error}</p>}
          <div className="row ">
              <div className="col-12 d-grid">
                  {loggingIn?
                  <div className='text-center text-muted my-3'>Loggin In...</div>
                  :
                  <button type="submit" className="btn btn-default btn-lg shadow-sm mt-3">Sign In</button>
                  }
              </div>
          </div>
      </form>
    </div>
  );
};

export default LoginForm;
