import React from 'react';

const List = ({ arenas }) => {
  return (
    <div className='row'>
        {arenas.map((arena) => (
            <div key={arena.id} className='col-12 col-md-6 '>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-auto position-relative">
                                <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-dark bg-white">
                                        <i className="bi bi-patch-check-fill size-10 vm" />
                                    </button>
                                </div>
                                <figure className="avatar avatar-80 rounded-15 border">
                                    <img src="pacxos.svg" alt='ARENA' className="w-100" />
                                </figure>
                            </div>
                            <div className="col position-relative">
                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                    <div className="tag tag-small bg-primary text-white me-2 size-6">
                                        OPEN
                                    </div>
                                </div>
                                <p className="mb-2"><small className="text-muted size-12">{arena.arenaName}</small></p>
                                <div className='d-flex flex-column gap-1'>
                                    <p className='m-0 size-12 fw-bold d-flex gap-1'><strong>Tables</strong><span>:</span><span>{arena.poolTables}</span></p>
                                    <p className='m-0 size-12 fw-bold d-flex gap-1'><strong>Address</strong><span>:</span><span>{arena.address}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}

        <div className='col-12 my-5'></div>
    </div>
  );
};

export default List;