import React, { useState,useEffect } from 'react';
///import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import Password from './ResetPassword';
import ResetPassword from './ResetPassword';

function Auth({ onLoginSuccess }) {
    const [referal, setReferal] = useState();
    const location = useLocation();
    const [viewMode, setViewMode] = useState('login'); // 'list' or 'map'

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (params.mode) {
            setViewMode(params.mode);
        }

        if (params.r) {
            localStorage.setItem("referalCode", params.r);
        }

        if (params.play) {
            localStorage.setItem("matchCode", params.play);
        }
    }, [location]);

    const toggleViewMode = (mode) => {
        setViewMode(mode);
    };

    return (
        <>
            <div>
                <main className="container-fluid h-100" style={{minHeight: 532}}>
                    <div className="row h-100">
                        <div className="col-11 col-sm-11 mx-auto">
                            {/* header */}
                            <div className="row">
                                <header className="header">
                                    <div className="row">
                                        <div className="col">
                                            <div className="logo-small">
                                                <img src="https://media.pacxos.com/icon.png" alt />
                                                <h5><span className="text-secondary fw-light">PACXOS</span></h5>
                                            </div>
                                        </div>
                                        <div className="col-auto align-self-center">
                                            {viewMode === 'login' ? (
                                                <a onClick={() => toggleViewMode('register')} href="#!">Sign Up</a>
                                            ) : (
                                                <a onClick={() => toggleViewMode('login')} href="#!">Sign In</a>
                                            )}
                                        </div>
                                    </div>
                                    {/* {referal?<span>Your Referal code is <b>{referal}</b></span>:<></>} */}
                                </header>
                            </div>
                            {/* header ends */}
                        </div>

                        <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">        
                            {viewMode === 'login' ? (
                                <LoginForm onLoginSuccess={onLoginSuccess} toggleViewMode={toggleViewMode} />
                            ) : (
                                <>
                                    {viewMode === 'password' ? (
                                        <ResetPassword onLoginSuccess={onLoginSuccess} />
                                    ) : (
                                        <RegisterForm onLoginSuccess={onLoginSuccess}/>
                                    )}
                                </>
                            )}
                            <div class="d-flex gap-2 w-100 justify-content-center  translate-top-50 transition-100 transition-delay-1700 my-3">
                                <a href="https://terms.pacxos.com" target="_blank" className='size-12'>Terms & Conditions</a>
                                <span className='size-12'>|</span>
                                <a href="https://policy.pacxos.com" target="_blank" className='size-12'>Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Auth;