import React, { useEffect,useState } from 'react';
import { NavLink,useNavigate } from 'react-router-dom';
import Timer from '../Sections/Timer'

function Bet() {
    const [token, setToken] = useState();
    const [account, setAccont] = useState([]);
    const [matches, setMatches] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            
            
            if(!token){
                setToken(tokenJson.token);
            }else{
                setAccont(tokenJson);
            
                fetchTopMatches(tokenJson.token);
            }
        } else {
            setToken('');
        }
    }, [token]);

    const fetchTopMatches = async (tokenString) => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/BetArena?token=${tokenString}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMatches(data);
        } catch (error) {
            console.error('Error fetching Matches:', error);
        }
    };

    function formatPeriodTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString([], options);
    }

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }
    

    function viewMatch(player,moderator,ref) {
        if(player||moderator){
            navigate(`/match/${ref}`);
        }else{
            localStorage.setItem("matchCode", ref);
            navigate('/join');
        }
    }
  return (
    <>
        <div>
            <div className='mt-5 py-5'>
                {matches.map((match) => (
                    <div key={match.id} className='border bg-white rounded-10 p-3 mb-4'>
                        <div className="d-flex align-items-center" onClick={()=>viewMatch(match.IsPlayer,match.IsModerator,match.matchRef)}>
                            {/* <div className="col-auto">
                                <div className="avatar avatar-50 p-1 shadow-none shadow-dark rounded-15">
                                    <div className="icons border border-danger text-danger rounded-12">
                                        <i className="bi bi-bullseye" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex-grow-1 ps-0">
                                <p className="text-secondary size-12 fw-bold mb-0">{match.matchRef}</p>
                                <p className='mb-0 size-12 text-dark fw-bold'>{match.ModeratorName}</p>
                            </div>
                            <div className="flex-grow-1 align-self-center text-end">
                                <p className="text-secondary text-muted size-10 mb-1">{formatPeriodDate(match.CreatedDate)}</p>
                                <p className='mb-0 size-12 fw-bold text-dark'>
                                    {match.MatchStatusTitle==='LIVE'?
                                        <p className='mb-0 size-12 fw-bold text-dark'><Timer initialDateTime={match.matchStart} /></p>
                                    :
                                        <p className='mb-0 size-12 fw-bold text-dark'>COMMING UP</p>
                                    }
                                </p>
                            </div>
                        </div>

                        <div className='mt-2'>
                            <div className='d-flex flex-wrap'>
                                {JSON.parse(match.BetOptions).map((option, index) => {
                                    // Array of different background color classes
                                    const badgeColors = ['bg-blue text-white', 'bg-indigo text-white', 'bg-cyan text-white', 'bg-pink text-white', 'bg-orange text-white'];
                                    // Determine the color based on the index
                                    const badgeColor = badgeColors[index % badgeColors.length];
                                    
                                    return (
                                        <span key={option.ID} className={`badge ${badgeColor} rounded-10 me-2 mb-2`}>
                                            {option.betOption}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>

                        <small>{match.matchDescription}</small>
                        
                        <div className='row mt-3'>
                            <div className='col-4 d-flex flex-column border-end'>
                                <small><em>Stake Amount</em></small>
                                <p className='fw-bold mb-0'>Ksh {match.stakeAmt}</p>
                            </div>
                            <div className='col-4 d-flex flex-column border-end'>
                                <small><em>Moderator Fee</em></small>
                                <p className='fw-bold mb-0'>Ksh {match.moderatorPlayerFee}</p>
                            </div>
                            <div className='col-4 d-flex flex-column'>
                                <small><em>Possible Win</em></small>
                                <p className='fw-bold mb-0'>Ksh {match.winAmt}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mb-3 text-center'>
                <img src="pacxos.svg" height='50px' alt />
            </div>
        </div>
    </>
  );
}

export default Bet;