import React from 'react';
import { useNavigate , NavLink } from 'react-router-dom';
import Timer from '../../Sections/Timer';

const BetsList = ({ bets }) => {
    const navigate = useNavigate();
    function viewMatch(player,moderator,ref) {
        if(player||moderator){
            navigate(`/match/${ref}`);
        }else{
            localStorage.setItem("matchCode", ref);
            navigate('/join');
        }
    }

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

  return (
    <div>
        {bets&&
            <>
                {bets.map((match) => (
                    <div key={match.id} className='border bg-white rounded-10 p-3 mb-4'>
                        <div className="d-flex align-items-center" onClick={()=>viewMatch(match.IsPlayer,match.IsModerator,match.matchRef)}>
                            {/* <div className="col-auto">
                                <div className="avatar avatar-50 p-1 shadow-none shadow-dark rounded-15">
                                    <div className="icons border border-danger text-danger rounded-12">
                                        <i className="bi bi-bullseye" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex-grow-1 ps-0">
                                <p className="text-secondary size-12 fw-bold mb-0">{match.matchRef}</p>
                                <p className='mb-0 size-12 text-dark fw-bold'><i className="bi bi-person-circle me-1" />{match.ModeratorName}</p>
                            </div>
                            <div className="flex-grow-1 align-self-center text-end">
                                <p className="text-secondary text-muted size-10 mb-1">{formatPeriodDate(match.CreatedDate)}</p>
                                <p className='mb-0 size-12 fw-bold text-dark'>
                                    {match.MatchStatusTitle==='LIVE'?
                                        <p className='mb-0 size-12 fw-bold text-dark'><Timer initialDateTime={match.matchStart} /></p>
                                    :
                                        <p className='mb-0 size-12 fw-bold text-dark'>COMMING UP</p>
                                    }
                                </p>
                            </div>
                        </div>

                        <small>{match.matchDescription}</small>

                        <div className='my-1'>
                            <div className='d-flex flex-wrap'>
                                {JSON.parse(match.BetOptions).map((option, index) => {
                                    // Array of different background color classes
                                    const badgeColors = ['text-blue', 'text-indigo', 'text-cyan', 'text-pink', 'text-orange'];
                                    // Determine the color based on the index
                                    const badgeColor = badgeColors[index % badgeColors.length];
                                    
                                    return (
                                        <span key={option.ID} className={`${badgeColor} me-2 size-12 fw-bold`}>
                                            #{option.betOption}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                        
                        <div className='row'>
                            <div className='col-4 d-flex flex-column border-end'>
                                <p className='fw-bold mb-0 size-12'>Ksh {match.stakeAmt}</p>
                                <small className='size-10'><em>Stake</em></small>
                            </div>
                            <div className='col-4 d-flex flex-column border-end'>
                                <p className='fw-bold mb-0 size-12'>Ksh {match.moderatorPlayerFee}</p>
                                <small className='size-10'><em>Moderator</em></small>
                            </div>
                            <div className='col-4 d-flex flex-column'>
                                <p className='fw-bold mb-0 size-12'>Ksh {match.winAmt}</p>
                                <small className='size-10'><em>Pool</em></small>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        }
    </div>
  );
};

export default BetsList;