import React, { useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoUrl, isPlaying, onClose, startMinute, endMinute }) => {
  const [playedSeconds, setPlayedSeconds] = useState(0);

  // Handle progress and stop video at the endMinute if it's provided
  const handleProgress = (state) => {
    setPlayedSeconds(state.playedSeconds);

    // Check if endMinute is provided and stop video when that time is reached
    if (endMinute !== null && state.playedSeconds >= endMinute * 60) {
      onClose(); // Close the video when the end time is reached
    }
  };

  return (
    <div style={popupStyles}>
      <div style={popupContentStyles}>
        <button onClick={onClose} style={closeButtonStyles}>Close</button>
        <ReactPlayer
          url={videoUrl}
          playing={isPlaying}
          controls={true}
          width="100%"
          height="100%"
          progressInterval={100} // Check progress every 100ms
          onProgress={handleProgress}
          config={{
            youtube: {
              playerVars: {
                start: startMinute ? startMinute * 60 : 0, // Start video at startMinute (converted to seconds) or 0 if null
              },
            },
          }}
        />
      </div>
    </div>
  );
};

// Styling for popup (same as before)
const popupStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const popupContentStyles = {
  backgroundColor: "#fff",
  padding: "10px",
  borderRadius: "10px",
  width: "90%",
  height: "80%",
  position: "relative",
};

const closeButtonStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  borderRadius: "10px",
  background: "red",
  color: "white",
  border: "none",
  padding: "10px",
  cursor: "pointer",
};

export default VideoPlayer;