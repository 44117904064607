import React, {useState} from 'react';
import { Carousel } from 'react-bootstrap';
import VideoPlayer from "../VideoPlayer";

const NewsCarousel = ({ news }) => {
    const [isVideoPlaying, setVideoPlaying] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [startMinute, setStartMinute] = useState(null);
    const [endMinute, setEndMinute] = useState(null);

    const handlePlayClick = (url, start, end) => {
        setVideoUrl(url);
        setStartMinute(start);
        setEndMinute(end);
        setVideoPlaying(true);
    };

    const handleCloseVideo = () => {
        setVideoPlaying(false);
    };

    const openPopup = (url) => {
        const popupWidth = 600; // Width of the pop-up
        const popupHeight = 400; // Height of the pop-up
        const left = window.innerWidth / 2 - popupWidth / 2; // Center the pop-up
        const top = window.innerHeight / 2 - popupHeight / 2; // Center the pop-up
        window.open(url, 'popup', `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
    };

  return (
    <div>
        {news&&
            <>
                <Carousel interval={5500} controls={false} indicators={true}>
                    {news.slice(0, 5).map((post) => (
                        <Carousel.Item key={post.id}>
                            <div className="card card-theme mb-4 overflow-hidden shadow-sm text-white" style={{minHeight:200}}>
                                <div className="overlay"></div>
                                <div className="coverimg h-100 w-100 position-absolute opacity-3" 
                                style={{
                                    backgroundImage: `url("${post.newsCover ? post.newsCover : 'assets/img/news3.jpg'}")`,
                                }}
                                >
                                        <img src="assets/img/news3.jpg" alt style={{display: 'none'}} />
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <div className="row mb-5">
                                            <div className="col">
                                                {post.newsType=="2"?
                                                    <span className="tag bg-dark">Watch</span>
                                                :
                                                    <span className="tag bg-dark">Read</span>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                {post.newsType=="2"?
                                                    <button
                                                    onClick={() => handlePlayClick(post.newsUrl,post.newsStart,post.newsEnd)}
                                                    className="btn btn-light text-dark avatar avatar-40 p-0 rounded-circle shadow-sm"
                                                    >
                                                        <i className="bi bi-play" />
                                                    </button>
                                                :
                                                    // <button
                                                    // onClick={() => openPopup(post.newsUrl)}
                                                    // className="btn btn-light text-dark avatar avatar-40 p-0 rounded-circle shadow-sm"
                                                    // >
                                                    //     <i className="bi bi-justify" />
                                                    // </button>
                                                    <a href={post.newsUrl} target='_blank' className="btn btn-light text-dark avatar avatar-40 p-0 rounded-circle shadow-sm">
                                                        <i className="bi bi-justify" />
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            {post.newsType=="2"?
                                                <a onClick={() => handlePlayClick(post.newsUrl,post.newsStart,post.newsEnd)} className="h4 mb-2 text-normal d-block text-white">
                                                {post.newsTitle}
                                                </a>
                                            :
                                                // <a onClick={() => openPopup(post.newsUrl)} className="h4 mb-2 text-normal d-block text-white">
                                                //     {post.newsTitle}
                                                // </a>
                                                <a href={post.newsUrl} target='_blank' className="h4 mb-2 text-normal d-block text-white">
                                                    {post.newsTitle}
                                                </a>
                                            }
                                            {/* <small className="text-white">{post.newsDesc}</small> */}
                                        </div>
                                    </div>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
                {isVideoPlaying && (
                    <VideoPlayer
                    videoUrl={videoUrl}
                    isPlaying={isVideoPlaying}
                    onClose={handleCloseVideo}
                    startMinute={startMinute}
                    endMinute={endMinute}
                    />
                )}
            </>
        }
    </div>
  );
};

export default NewsCarousel;