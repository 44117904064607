import React, { useState,useEffect } from 'react';
///import { useSQLite } from 'react-sqlite';
////import { saveAccountDataToLocalStorage, getAuthDataFromLocalStorage } from "../../Data";

const Settings = () => {
  const [token, setToken] = useState();
  const [settingsData, setSettingsData] = useState({
    token: '',
    country: '254',
    phoneNumber: '',
    otp: '',
  });

  const [isPhoneVerified, setPhoneVerified] = useState(false);
  const [saving, setSaving] = useState(false);
  const [verifyingPhone, setVerifyingPhone] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const jsonData = localStorage.getItem('pacxosData');
    if (jsonData) {
        const tokenJson = JSON.parse(jsonData);
        console.log(tokenJson);
        setToken(tokenJson.token);
        setSettingsData({ ...settingsData, ['token']: tokenJson.token });
    } else {
        setToken('');
    }
  }, []);

  const handleChange = (e) => {
    setSettingsData({ ...settingsData, [e.target.name]: e.target.value });
    setSaving(false);
    setError(null);
  };

  const validatePhone = async (e) => {
    e.preventDefault();
    setVerifyingPhone(true);
    setSaving(true);
    try {
        const response = await fetch(`https://api.pacxos.com/v1/api/ValidateNewPhone`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(settingsData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('Phone Verification failed,'+ errorResponse);
          setVerifyingPhone(false);
          setSaving(false);
          return; // Exit the function if there's an error
        } 

        setPhoneVerified(true);
        setVerifyingPhone(false);
        setSaving(false);
        return; 
    } catch (error) {
      setError('Phone Verification failed. Try again later');
      ////console.error('Error:', error);
      setVerifyingPhone(false);
      setSaving(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
        const response = await fetch(`https://api.pacxos.com/v1/api/ChangeNumber`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(settingsData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('Failed. '+errorResponse);
          setSaving(false);
          return; // Exit the function if there's an error
        } 

        const data = await response.json();
        ////console.log(data);
        ///saveAccountDataToLocalStorage(data);
        const jsonData = JSON.stringify(data);
        localStorage.setItem("pacxosData", jsonData);
        
        setSaving(false);
        window.location.reload();
        return;
    } catch (error) {
        setError('Login failed. Try again later');
        ///console.error('Error:', error);
        setSaving(false);
    }
  };

  return (
    <div>
        {error && <p className="text-danger my-3">{error}</p>}
        <form onSubmit={handleSubmit}>
          {isPhoneVerified?
            <>
              <div>
                <div className='mt-3'>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <p className='mb-0'>OTP</p>
                      <small>Verify your phone number, enter OTP send to you by SMS.</small>
                    </div>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="tel" className="form-control" placeholder="OTP" name='otp' value={settingsData.otp} onChange={handleChange} id="otp" />
                    <label htmlFor="otp">OTP</label>
                  </div>
                </div>
                {saving?
                  <div className='text-center text-muted my-3'>Please Wait...</div>
                :
                  <div className='d-flex justify-content-between gap-2'>
                    <button type="button" onClick={validatePhone} className="btn btn-dark btn-sm shadow-sm">Resend</button>
                    <button type="submit" className="btn btn-default btn-lg shadow-sm mt-3">Save Changes</button>
                  </div>
                }
              </div>
            </>
          :
            <>
              <div className='row'>
                <div className='col-3'>
                    <div className="form-floating mb-3">
                        <select className="form-control" id="country" name="country" value={settingsData.country} onChange={handleChange}>
                            <option value='254' selected>+254</option>
                            <option value='255'>+255</option>
                            <option value='256'>+256</option>
                        </select>
                        <label htmlFor="country">Contry</label>
                    </div>
                </div>
                <div className='col-9'>
                    <div className="form-floating mb-3">
                        <input type="tel" className="form-control" placeholder="Phone Number" name='phoneNumber' value={settingsData.phoneNumber} onChange={handleChange} id="phoneNumber" />
                        <label htmlFor="phoneNumber">Phone Number</label>
                    </div>
                </div>
              </div> 
              {verifyingPhone?
                <div className='text-center text-muted'>Validating Phone Number...</div>
              :
                <button type="button" onClick={validatePhone} className="btn btn-default btn-lg shadow-sm">Validate</button>
              }
            </> 
          }
      </form>
    </div>
  );
};

export default Settings;
