import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet'; 
import "leaflet/dist/leaflet.css";
import customMarkerIcon from './arena-pin.svg';

const Map = ({ arenas }) => {
    
    const customIcon = new L.Icon({
        iconUrl: customMarkerIcon, // URL to your custom marker icon
        iconSize: [32, 32], // Size of the icon
        iconAnchor: [15, 20], // Anchor point of the icon (centered at the bottom)
    });

    return (
        <div className='row'>
            {/* {arenas.map((arena) => (
                
            ))} */}
            <div style={{ display: "flex" }}>
                {/* <MapContainer center={[-1.3419523750074869, 36.66662394232841]} zoom={2} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {arenas.map((arena) => (
                        <Marker key={arena.id} position={[parseFloat(arena.lat), parseFloat(arena.lng)]}>
                        <Popup>{arena.arenaName}</Popup>
                        </Marker>
                    ))}
                </MapContainer> */}
                <MapContainer style={{ height: '100vh', width: '100%', position: 'relative', outlineStyle: 'none', zIndex: 1 }} center={[-1.3419523750074869, 36.66662394232841]} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="Google Maps"
                        url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                    />
                    {arenas.map((arena) => (
                        <Marker key={arena.id} position={[parseFloat(arena.lat), parseFloat(arena.lng)]} icon={customIcon}>
                            <Popup>
                                <div>
                                    <div className="row">
                                        <div className="col-auto position-relative">
                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-3 z-index-1 ">
                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-dark bg-white">
                                                    <i className="bi bi-patch-check-fill size-10 vm" />
                                                </button>
                                            </div>
                                            <figure className="avatar avatar-50 rounded-15 border">
                                                <img src="pacxos.svg" alt='ARENA' className="w-100" />
                                            </figure>
                                            <div className=" mt-3">
                                                <div className="tag tag-small bg-primary text-white me-2 size-6">
                                                    OPEN
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col position-relative">
                                            <p className="mb-2"><small className="text-muted size-12">{arena.arenaName}</small></p>
                                            <div className='d-flex flex-column gap-1'>
                                                <p className='m-0 size-12 fw-bold d-flex gap-1'><strong>Tables</strong><span>:</span><span>{arena.poolTables}</span></p>
                                                <p className='m-0 size-12 fw-bold text-muted d-flex gap-1'><span>{arena.address}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
        </div>
    );
};

export default Map;