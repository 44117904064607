import React, { useState,useEffect } from 'react';
///import { useSQLite } from 'react-sqlite';
////import { saveAccountDataToLocalStorage, getAuthDataFromLocalStorage } from "../../Data";

const ChangeName = () => {
  const [token, setToken] = useState();
  const [profileData, setProfileData] = useState({
    Token: '',
    Nickname: '',
  });

  const [savingProfile, setSavingProfile] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const jsonData = localStorage.getItem('pacxosData');
    if (jsonData) {
        const tokenJson = JSON.parse(jsonData);
        console.log(tokenJson);
        setToken(tokenJson.token);
        setProfileData({ ...profileData, ['Token']: tokenJson.token });
    } else {
        setToken('');
    }
  }, []);

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
    setSavingProfile(false);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSavingProfile(true);
    try {
        const response = await fetch(`https://api.pacxos.com/v1/api/ChangeName`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(profileData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('Failed. '+errorResponse);
          setSavingProfile(false);
          return;
        } 

        const data = await response.json();
        
        const jsonData = JSON.stringify(data);
        localStorage.setItem("pacxosData", jsonData);
        setSavingProfile(false);
        window.location.reload();
        return;
    } catch (error) {
        setError('Login failed. Try again later');
        setSavingProfile(false);
        return;
    }
  };

  return (
    <div>
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-3">
              <input type="text" className="form-control" placeholder="Nickname" name='Nickname' value={profileData.Nickname} onChange={handleChange} id="nickname" />
              <label htmlFor="nickname">Nickname</label>
          </div>     
          
          {error && <p className="text-danger my-3">{error}</p>}
          <div className="row ">
              <div className="col-12 d-grid">
                  {savingProfile?
                    <div className='text-center text-muted my-3'>Saving...</div>
                  :
                    <button type="submit" className="btn btn-default btn-lg shadow-sm mt-3">Save Name</button>
                  }
              </div>
          </div>
      </form>
    </div>
  );
};

export default ChangeName;