import React, { useEffect,useState } from 'react';
import { useParams,NavLink } from 'react-router-dom';

function End() {
    const [token, setToken] = useState();
    const [match, setMatch] = useState([]);
    const [winner, setMatchWinner] = useState([]);
    const [error, setError] = useState(null);
    const [endingMatch, setMatchEnding] = useState(false);
    const [confirmEndMatch, setMatchEndConfirmation] = useState(false);
    const [machEnded, setMatchEnded] = useState(false);
    const [selectedBetId, setSelectedBetId] = useState(null);
    const [players, setPlayers] = useState([]);
    const [viewMode, setViewMode] = useState('list'); 
    const { id } = useParams();
    
    const [winners, setMatchWinners] = useState('');
    const [selectedPlayerNames, setSelectedPlayerNames] = useState('');

    const handlePlayerClick = (playerId,playerName) => {
        setSelectedBetId(0);
        toggleWinner(playerId, playerName);
    };

    
    const handleBetOptionClick = (option) => {
        // setEntryData(prevState => ({
        //     ...prevState,
        //     Note: `${option.name}`, // Update Note
        //     betId: option.id // Update betId
        // }));
        setMatchWinners(option.name);
        setSelectedPlayerNames(option.name);
        setSelectedBetId(option.id);
    };

    const toggleWinner = (playerId, playerName) => {
        // Convert the comma-separated strings to arrays for easier manipulation
        const winnersArray = winners ? winners.split(',') : [];
        const namesArray = selectedPlayerNames ? selectedPlayerNames.split(',') : [];
        
        // console.log(winners);
        // console.log(winnersArray);
        
        // console.log(selectedPlayerNames);
        // console.log(namesArray);
    
        // Update winners
        if (winnersArray.includes(playerId.toString())) {
          setMatchWinners(winnersArray.filter(id => id !== playerId.toString()).join(','));
        } else {
          setMatchWinners([...winnersArray, playerId].join(','));
        }

        // console.log(winners);
        // console.log(winnersArray);
        
        // console.log(winners);
        // console.log(winnersArray);
    
        // Update selected player names
        if (namesArray.includes(playerName)) {
          setSelectedPlayerNames(namesArray.filter(name => name !== playerName).join(','));
        } else {
          setSelectedPlayerNames([...namesArray, playerName].join(','));
        }

        // console.log(winners);
        // console.log(winnersArray);
        
        // console.log(selectedPlayerNames);
        // console.log(namesArray);
    };
    
    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);

            if(!token){
                setToken(tokenJson.token);
            }else{
                ///console.log(tokenJson.token);
                fetchMatch(id,tokenJson.token);
            }
        } else {
            setToken('');
        }
    }, [id,token]);

    const confirmToEndMatch=()=>{
        setMatchEndConfirmation(true);
    }
    
    const cancelMatchConfirmation=()=>{
        setMatchEndConfirmation(false);
    }

    const fetchMatch = async (id,token) => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Match?id=${id}&token=${token}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMatch(data);

            setMatchWinner({
                name: data.winnerName
            });

            ///GET MATCH PLAYERS
            fetchPlayers(data.id,token);
        } catch (error) {
            console.error('Error fetching match:', error);
        }
    };

    const fetchPlayers = async (match,token) => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Players?match=${match}&token=${token}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPlayers(data);
        } catch (error) {
            console.error('Error fetching players:', error);
        }
    };
    
    const endMatch = async (id,winners,token) => {
        setError(null);
        setMatchEnding(true);
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/MatchSettlement?id=${id}&winners=${winners}&token=${token}`);
            if (!response.ok) {
                const errorResponse = await response.text();
                setMatchEnding(false);
                setMatchEndConfirmation(false);
                setError('Failed. Something went wrong');
                return;
            }
            ///const data = await response.json();
            fetchMatch(id,token);
            setMatchEnding(false);
            setMatchEnded(true);
        } catch (error) {
            setMatchEnding(false);
            setMatchEndConfirmation(false);
            setError('Failed. Try again later');
            ///console.error('Error fetching game:', error);
        }
    };

    const completeMatch = async (id,selectedBetId,token) => {
        setError(null);
        setMatchEnding(true);
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/BetSettlement?id=${id}&winner=${selectedBetId}&token=${token}`);
            if (!response.ok) {
                const errorResponse = await response.text();
                setMatchEnding(false);
                setMatchEndConfirmation(false);
                setError('Failed. Something went wrong');
                return;
            }
            ///const data = await response.json();
            fetchMatch(id,token);
            setMatchEnding(false);
            setMatchEnded(true);
        } catch (error) {
            setMatchEnding(false);
            setMatchEndConfirmation(false);
            setError('Failed. Try again later');
            ///console.error('Error fetching game:', error);
        }
    };

    return (
        <>
            <div>
                <div className='d-flex mt-5 pt-5 justify-content-between'>
                    <div>
                    <p className="size-10 mb-1"><span className='text-danger'><i className="bi bi-record-circle size-16 me-1" />{match.matchStatusTitle}</span> | {match.gameName}</p>
                        <h5 className='text-center mb-2'>{match.matchRef}</h5>
                    </div>
                    {/* {match.matchRole==='MODERATOR'?
                        {renderMatchButton}
                        :
                        <NavLink to={`/`}  className="btn btn-default btn-44 shadow-none">
                                <i class="bi bi-arrow-left"></i>
                        </NavLink>
                    } */}
                    <NavLink to={`/`}  className="btn btn-default btn-44 shadow-none">
                        <i class="bi bi-arrow-left"></i>
                    </NavLink>
                </div>
                {error && <p className="text-danger my-3">{error}</p>}
                {machEnded?
                    <div className='text-center'>
                        <h1 className='mb-3'>MATCH ENDED</h1>
                        {/* <small>WINNER</small>
                        <div className="w-100 d-flex justify-content-center mb-4">
                            <div className="row w-auto align-items-center border border-secondary border-1 my-2 rounded-15 p-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-40 shadow-sm rounded-18">
                                        <i class="bi bi-person-circle"></i>
                                    </figure>
                                </div>
                                <div className="col-auto text-start px-0">
                                    <p className="mb-0">{winner.name}</p>
                                </div>
                            </div>
                        </div> */}
                        <NavLink to={`/`}  className="btn btn-default btn-lg shadow-none">
                            <i class="bi bi-house me-2"></i> Back Home
                        </NavLink>
                    </div>
                :
                    <div className='mt-3 mb-5 pb-5'>
                        {match.gameId==1 ?
                            <>
                                <p className='fw-bold text-center mb-3'>Select the option that won the match, playes who bet on the selected option will be paid.</p>
                                {
                                    match.betOptions.map((option, index) => {
                                        const badgeColors = ['border-blue text-blue', 'border-indigo text-indigo', 'border-cyan text-cyan', 'border-pink text-pink', 'border-orange text-orange'];
                                        const badgeSelected = ['bg-blue text-white', 'bg-indigo text-white', 'bg-cyan text-white', 'bg-pink text-white', 'bg-orange text-white'];
                                        // Determine the color based on the index
                                        ///const badgeColor = badgeColors[index % badgeColors.length];
                                        const badgeColor = selectedBetId === option.id
                                        ? badgeSelected[index % badgeSelected.length] // Danger background and text for selected option
                                        : badgeColors[index % badgeColors.length]; 
                                        
                                        return (
                                            <div 
                                                key={option.id} 
                                                className={`${badgeColor} border rounded-10 p-2 mt-2 mb-3 d-flex justify-content-between`}
                                                onClick={() => handleBetOptionClick(option)} // Handle click event
                                                style={{ cursor: 'pointer' }}>
                                                <span className="fw-bold">{option.name}</span>
                                                {
                                                    selectedBetId === option.id
                                                    ? <i class="bi bi-check-square-fill"></i> // Danger background and text for selected option
                                                    : <i class="bi bi-square"></i>
                                                }
                                            </div>
                                        );
                                    })
                                }

                                <div className='text-center my-4'>
                                    {
                                        confirmEndMatch?
                                            <div className="card bg-success text-dark shadow-sm shadow-success bg-opac border mb-4">
                                                <div className="card-body">
                                                    <h5 className="modal-title text-center" id="exampleModalLabel">Confirm selected option and end match</h5>
                                                    <p className='text-center'>Confirm <b>{selectedPlayerNames}</b> was the winning bet.</p>
                                                    {
                                                        endingMatch?
                                                            <p className='text-center'>PLEASE WAIT...</p>
                                                        :
                                                            <div className="d-flex justify-content-between">
                                                                <button type="button" className="btn btn-secondary" onClick={()=>cancelMatchConfirmation()}>Cancel</button>
                                                                <button type="button" className="btn btn-success" onClick={() => completeMatch(id,selectedBetId,token)}>Confirm</button>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                        <button type="button" onClick={() => confirmToEndMatch()} className="btn btn-default btn-lg shadow-sm">End Match</button>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <p className='fw-bold text-center mb-3'>Select player or players that won the match.</p>
                                
                                <div className='row'>
                                    {players.map((player) => (
                                        <div key={player.id} className='col-6 col-md-4'>
                                            <div 
                                                className={`card my-2 ${winners.includes(player.id) ? 'border border-3 border-secondary bg-light' : ''}`} 
                                                onClick={() => handlePlayerClick(player.id,player.playerName)}
                                                style={{ cursor: 'pointer' }}>
                                                <div className="card-body text-center">
                                                    <figure className="avatar avatar-40 shadow-sm rounded-18">
                                                        <i class="bi bi-person-circle"></i>
                                                    </figure>
                                                    <p className="mb-2">{player.playerName}</p>
                                                    {player.playerNote?<p className='text-secondary size-12 mb-0'><span>{player.playerNote}</span></p>:<p className='text-secondary size-12 mb-0'>******</p>}

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className='text-center my-4'>
                                    {
                                        confirmEndMatch?
                                            <div className="card bg-success text-dark shadow-sm shadow-success bg-opac border mb-4">
                                                <div className="card-body">
                                                    <h5 className="modal-title text-center" id="exampleModalLabel">Confirm selected players and end match</h5>
                                                    <p className='text-center'>Confirm <b>{selectedPlayerNames}</b> was the winning bet.</p>
                                                    {
                                                        endingMatch?
                                                            <p className='text-center'>PLEASE WAIT...</p>
                                                        :
                                                            <div className="d-flex justify-content-between">
                                                                <button type="button" className="btn btn-secondary" onClick={()=>cancelMatchConfirmation()}>Cancel</button>
                                                                <button type="button" className="btn btn-success" onClick={() => endMatch(id,winners,token)}>Confirm</button>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                        <button type="button" onClick={() => confirmToEndMatch()} className="btn btn-default btn-lg shadow-sm">End Match</button>
                                    }
                                </div>
                            </>
                        }
                        {/* {endingMatch?
                            <p className="text-center fw-bold">ENDING MATCH...</p>
                            :
                            <div className='text-center my-4'>
                                
                            </div>
                        } */}
                        
                    </div>
                }
                
            </div>
        </>
    );
}

export default End;