import React,{} from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = ({ onLogout, showInstallButton, onInstallClick, isOnline }) => {

    const handleLogout = () => {
        onLogout();
    };

    return (
        <>
            <div>
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <img src="https://media.pacxos.com/icon.png" height='30px' alt className="rounded-18" />
                        </div>
                        <div className="col text-center">
                            {/* <div className="logo-small">
                                <img src="pacxos.png" alt />
                                <h5><span className="text-secondary fw-light">PACXOS</span><br /> Peer 2 Peer</h5>
                            </div> */}
                        </div>
                        <div className="col-auto">
                            <NavLink to="/profile" target="_self" className="btn bg-none border btn-44">
                                <i className="bi bi-person-circle" />
                            </NavLink>
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
}

export default Navigation;