import React, { useEffect,useState } from 'react';
import { useParams,NavLink,useNavigate } from 'react-router-dom';

function Game() {
    const [token, setToken] = useState();
    const [verified, setVerified] = useState(false);
    const [matchData, setMatchData] = useState({
        GameId: '',
        Players: 0,
        StakeAmt: 50,
        ModeratorFee: 0,
        // extraPlayerFee: 0,
        Token: '',
        MatchDescription: '',
        BetOptions: [{ name: '' }, { name: '' }],
        post: false
    });
    const [game, setGame] = useState([]);
    const [creatingMatch, setMatchCreation] = useState(false); 
    const [match, setMatch] = useState([]);
    const [matchOn, setMatchMode] = useState(false); 
    const { id } = useParams();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            console.log(tokenJson);
            setToken(tokenJson.token);

            if(tokenJson.verified=='1'){
                setVerified(true);
            }else{
                setVerified(false);
            }
            
            fetchGame(id);
        } else {
            setToken('');
        }
    }, [id]);
    
    const handleChange = (e) => {
        setMatchData({ ...matchData, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (e) => {
        setMatchData({ ...matchData, post: e.target.checked });
    };

    const handleBetOptionChange = (index, event) => {
        const newBetOptions = [...matchData.BetOptions];
        newBetOptions[index].name = event.target.value;
        setMatchData({ ...matchData, BetOptions: newBetOptions });
    };

    const addBetOption = () => {
        setMatchData({
            ...matchData,
            BetOptions: [...matchData.BetOptions, { name: '' }]
        });
    };
    
    const removeBetOption = (index) => {
        if (matchData.BetOptions.length > 2) {  // Ensure at least 2 options remain
            const newBetOptions = matchData.BetOptions.filter((_, i) => i !== index);
            setMatchData({ ...matchData, BetOptions: newBetOptions });
        }
    };

    const fetchGame = async (id) => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Game/${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setGame(data);

            // if(data.id==2){
            //     setMatchData({
            //         ...matchData,
            //         MatchDescription: data.gameDesc
            //     });
            // }
        } catch (error) {
            ///console.error('Error fetching game:', error);
        }
    };

    const fetchMatch = async (matchId) => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Match?id=${matchId}?token=${token}`);

            if (!response.ok) {
                const errorResponse = await response.text();
                ///console.error('Failed. '+errorResponse);
                setMatchMode(false);
            }
            const data = await response.json();
            setMatch(data);
            setMatchMode(true);
        } catch (error) {
            ///console.error('Error fetching game:', error);
            setMatchMode(false);
        }
    };

    const startMatchSubmit = async (e) => {
        e.preventDefault();
        const updatedMatchData = { ...matchData, GameId: game.id, Token: token };
        // console.log(game.id);
        // console.log(token);
        // console.log(matchData);
        // console.log(updatedMatchData);

        // Validation
        // if (!matchData.GameId) {
        //     setError('Game ID is required');
        //     return;
        // }

        // if (matchData.Players <= 0) {
        //     setError('Number of players must be greater than zero');
        //     return;
        // }

        if (updatedMatchData.StakeAmt < 50) {
            setError('Failed. Minimum stake amount is 50');
            return;
        }

        if (updatedMatchData.ModeratorFee < 0) {
            setError('Moderator fee must be zero or greater');
            return;
        }

        if (!updatedMatchData.MatchDescription) {
            setError('Match description is required');
            return;
        }

        if(game.id==1){
            // Validate BetOptions
            const emptyBetOption = updatedMatchData.BetOptions.some(option => !option.name.trim());
            if (emptyBetOption) {
                setError('All bet options must be filled');
                return;
            }

            const betOptionNames = updatedMatchData.BetOptions.map(option => option.name.trim().toLowerCase());
            const duplicates = betOptionNames.filter((item, index) => betOptionNames.indexOf(item) !== index);
    
            if (duplicates.length > 0) {
                setError(`Duplicate bet options found: ${[...new Set(duplicates)].join(", ")}`);
                return;
            }

            setMatchCreation(true);
            try {
                console.log("Post Data: "+JSON.stringify(updatedMatchData));
                const response = await fetch(`https://api.pacxos.com/v1/api/Bet`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedMatchData)
                });
    
                if (!response.ok) {
                    const errorResponse = await response.text();
                    setMatchCreation(false);
                    setError('Failed. Try again later');
                    console.error('Failed. '+errorResponse);
                }
    
                ///const data = await response.json();
                ///fetchMatch(id);
                setMatchCreation(false);
                navigate('/live');
            } catch (error) {
                setMatchCreation(false);
                setError('Failed. Try again.');
                console.error('Error:', error);
                return;
            }
        }else{
            setMatchCreation(true);
            try {
                console.log("Post Data: "+JSON.stringify(updatedMatchData));
                const response = await fetch(`https://api.pacxos.com/v1/api/Match`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedMatchData)
                });
    
                if (!response.ok) {
                    const errorResponse = await response.text();
                    setMatchCreation(false);
                    setError('Failed. Try again later');
                    console.error('Failed. '+errorResponse);
                }
    
                ///const data = await response.json();
                ///fetchMatch(id);
                setMatchCreation(false);
                navigate('/live');
            } catch (error) {
                setMatchCreation(false);
                setError('Failed. Try again.');
                console.error('Error:', error);
                return;
            }
        }

        // if(updatedMatchData.StakeAmt<50){
        //     setError('Failed. Minimum stake amount is 50');
        //     return;
        // }else{
            
        // }
    };

    return (
        <div className="game-details mt-5 pt-5">
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='mb-0 fw-bold size-10'>#{game.gameRef}</p>
                    <h5 className='text-center mb-2'>{game.gameName}</h5>
                </div>
                <NavLink to={`/`}  className="btn btn-default btn-44 shadow-none">
                    <i class="bi bi-arrow-left"></i>
                </NavLink>
            </div>
            {matchOn?
                <div className="border rounded bg-light mt-3 p-2 flex-grow-1 mb-2">
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Match Code</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.matchRef}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Players</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.players}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Stake</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.stakeAmt}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Moderator</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.moderatorName}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Moderator Fee</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.moderatorFee}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Escrow Fee</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.escrowAmt}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Start</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.matchStart}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>End</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.matchEnd}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Earnings</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.winAmt}</b>
                    </div>
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Winner</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{match.winnerName}</b>
                    </div>
                </div>
                :
                <div>
                    {/* <small className='text-center'>{game.gameDesc}</small>
                        <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Players</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{game.minPlayers===game.maxPlayers?<span>2</span>:<b><span>{game.minPlayers}</span><span> to </span><span>{game.maxPlayers}</span></b>}</b>
                    </div> 
                    <div className="d-flex mt-2 gap-2 justify-content-between align-items-center">
                        <b>Escrow</b>
                        <div className="dashed-line flex-grow-1 my-2" />
                        <b className="text-muted">{game.escrowFee}%</b>
                    </div> */}
                </div>
            }
            {matchOn?
                <div>
                    <p>Wait for players to join match</p>
                </div>
                :
                <>
                    {game.id?
                    <>
                        {game.id==1?
                        <>
                            <form onSubmit={startMatchSubmit}>
                                {/* <h6 className="mb-4 text-center"><span className="text-secondary fw-light me-2">New</span>Match</h6>             */}

                                {/* <p className='text-center mb-0'><small>Description & Instructions</small></p> */}
                                <textarea name="MatchDescription" className="form-control text-center my-4 rounded-2" id="MatchDescription" rows="3" onChange={handleChange} placeholder='Enter match description & instructions...' value={matchData.MatchDescription}/>
                                
                                {/* <p className='text-center mb-0'><small>Set number of players</small></p>
                                <div className='d-flex gap-2 mb-3'>
                                    <button type="button" className="btn btn-default btn-lg shadow-sm" onClick={handleDecrement}>-</button>
                                    <input type="number" min={2} className="form-control text-center" placeholder="Players" name='Players' value={matchData.Players} onChange={handleChange} id="Players" />
                                    <button type="button" className="btn btn-default btn-lg shadow-sm" onClick={handleIncrement}>+</button>
                                </div> */}

                                <div className='row'>
                                    <div className='col-7 d-flex flex-column justify-content-end mb-3'>
                                        <small><b>Stake Amount</b></small>
                                        <small>Minimum amount Ksh. 50, enter stake amount.</small>
                                    </div>
                                    <div className='col-5 mb-3'>
                                        <div className="form-group form-floating">
                                            <input 
                                                type="number" 
                                                min={50}
                                                name='StakeAmt' 
                                                value={matchData.StakeAmt} 
                                                onChange={handleChange} 
                                                id="stake"
                                                placeholder="Stake Amount" 
                                                className="form-control"/>
                                            {/* <label className="form-control-label" htmlFor="stake">Stake Amount</label> */}
                                            <span className="text-color-theme tooltip-btn">
                                                Ksh
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-7 d-flex flex-column justify-content-end mb-3'>
                                        <small><b>Moderator Fee</b></small>
                                        <small>Enter charges to be deducted from each player.</small>
                                    </div>
                                    <div className='col-5 mb-3'>
                                        <div className="form-group form-floating">
                                            <input 
                                                type="number" 
                                                name='ModeratorFee' 
                                                value={matchData.ModeratorFee} 
                                                onChange={handleChange} 
                                                id="ModeratorFee"
                                                placeholder="Moderator Fee" 
                                                className="form-control"/>
                                            {/* <label className="form-control-label" htmlFor="ModeratorFee">Moderator Fee</label> */}
                                            <span className="text-color-theme tooltip-btn">
                                                Ksh
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className='col-7 d-flex flex-column justify-content-end mb-3'>
                                        <small><b>Additional player Fee</b></small>
                                        <small>After the first 2 players have joined</small>
                                    </div>
                                    <div className='col-5 mb-3'>
                                        <div className="form-group form-floating">
                                            <input 
                                                type="number"
                                                name='extraPlayerFee' 
                                                value={matchData.extraPlayerFee} 
                                                onChange={handleChange} 
                                                id="extraPlayerFee"
                                                placeholder="Additional player Fee" 
                                                className="form-control"/>
                                            <label className="form-control-label" htmlFor="extraPlayerFee">Additional player Fee</label> 
                                            <span className="text-color-theme tooltip-btn">
                                                Ksh
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                                
                                <div className="border  rounded-2 bg-light mt-3 mb-4 p-2">
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex flex-column'>
                                            <b>Bet Options:</b>
                                            <small>Set unique bet options for match, 2 or more</small>
                                        </div>
                                        <button type="button" className='btn btn-dark rounded-2' onClick={addBetOption}><i className="bi bi-plus-lg" /></button>
                                    </div>
                                    <hr />
                                    {matchData.BetOptions.map((betOption, index) => (
                                        <div  key={index} className="form-group form-floating mb-2">
                                            <input type="text"
                                                value={betOption.name}
                                                onChange={(e) => handleBetOptionChange(index, e)}
                                                placeholder={`Option ${index + 1}`} className="form-control" id={`Option${index + 1}`}/>
                                            <label className="form-control-label" htmlFor={`Option${index + 1}`}>{`Option ${index + 1}`}</label>
                                            <button type="button" className="text-color-theme tooltip-btn" onClick={() => removeBetOption(index)}
                                                disabled={matchData.BetOptions.length <= 2}>
                                                <i className="bi bi-x-lg" />
                                            </button>
                                        </div>
                                    ))}
                                </div>

                                {verified &&
                                    <div className='mb-4'>
                                        <div className='d-flex justify-content-between'>
                                            <label><b>List match in Bet Arena</b></label>
                                            <div className="form-check form-switch">
                                                <label className="form-check-label" htmlFor="post">YES</label>
                                                <input type="checkbox" className="form-check-input" id='post' checked={matchData.post}
                                                    onChange={handleCheckboxChange} />
                                            </div>
                                        </div>
                                        <em><small>Click the <b>YES</b> to list this match in the Bet Arena for everyone to join. you will be charged Ksh. 10 from your wallet.</small></em>
                                    </div>
                                }
                                
                                {error && <p className="text-danger my-3">{error}</p>}
                                
                                <div className="row mt-3 mb-5 pb-5">
                                    <div className="col-12 d-grid mb-5">
                                        {creatingMatch? <p className='text-center fw-bold text-muted'>CREATING MATCH...</p>:<button type="submit" className="btn btn-default rounded-2 btn-lg shadow-sm">CREATE MATCH</button>}
                                    </div>
                                </div>
                            </form>
                        </>
                        :
                        <>
                                <form onSubmit={startMatchSubmit}>
                                {/* <h6 className="mb-4 text-center"><span className="text-secondary fw-light me-2">New</span>Match</h6>             */}

                                {/* <p className='text-center mb-0'><small>Description & Instructions</small></p> */}
                                <textarea name="MatchDescription" className="form-control text-center my-4 rounded-2" id="MatchDescription" rows="3" onChange={handleChange} placeholder='Enter match description & instructions...' value={matchData.MatchDescription}/>
                                
                                {/* <p className='text-center mb-0'><small>Set number of players</small></p>
                                <div className='d-flex gap-2 mb-3'>
                                    <button type="button" className="btn btn-default btn-lg shadow-sm" onClick={handleDecrement}>-</button>
                                    <input type="number" min={2} className="form-control text-center" placeholder="Players" name='Players' value={matchData.Players} onChange={handleChange} id="Players" />
                                    <button type="button" className="btn btn-default btn-lg shadow-sm" onClick={handleIncrement}>+</button>
                                </div> */}

                                <div className='row'>
                                    <div className='col-7 d-flex flex-column justify-content-end mb-3'>
                                        <small><b>Stake Amount</b></small>
                                        <small>Minimum amount Ksh. 50, enter stake amount.</small>
                                    </div>
                                    <div className='col-5 mb-3'>
                                        <div className="form-group form-floating">
                                            <input 
                                                type="number" 
                                                min={50}
                                                name='StakeAmt' 
                                                value={matchData.StakeAmt} 
                                                onChange={handleChange} 
                                                id="stake"
                                                placeholder="Stake Amount" 
                                                className="form-control"/>
                                            {/* <label className="form-control-label" htmlFor="stake">Stake Amount</label> */}
                                            <span className="text-color-theme tooltip-btn">
                                                Ksh
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-7 d-flex flex-column justify-content-end mb-3'>
                                        <small><b>Moderator Fee</b></small>
                                        <small>Enter charges to be deducted from each player.</small>
                                    </div>
                                    <div className='col-5 mb-3'>
                                        <div className="form-group form-floating">
                                            <input 
                                                type="number" 
                                                name='ModeratorFee' 
                                                value={matchData.ModeratorFee} 
                                                onChange={handleChange} 
                                                id="ModeratorFee"
                                                placeholder="Moderator Fee" 
                                                className="form-control"/>
                                            {/* <label className="form-control-label" htmlFor="ModeratorFee">Moderator Fee</label> */}
                                            <span className="text-color-theme tooltip-btn">
                                                Ksh
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className='col-7 d-flex flex-column justify-content-end mb-3'>
                                        <small><b>Additional player Fee</b></small>
                                        <small>After the first 2 players have joined</small>
                                    </div>
                                    <div className='col-5 mb-3'>
                                        <div className="form-group form-floating">
                                            <input 
                                                type="number"
                                                name='extraPlayerFee' 
                                                value={matchData.extraPlayerFee} 
                                                onChange={handleChange} 
                                                id="extraPlayerFee"
                                                placeholder="Additional player Fee" 
                                                className="form-control"/>
                                            <label className="form-control-label" htmlFor="extraPlayerFee">Additional player Fee</label> 
                                            <span className="text-color-theme tooltip-btn">
                                                Ksh
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                                
                                {error && <p className="text-danger my-3">{error}</p>}
                                
                                <div className="row mt-3 mb-5 pb-5">
                                    <div className="col-12 d-grid">
                                        
                                        {creatingMatch? <p className='text-center fw-bold text-muted'>CREATING MATCH...</p>:<button type="submit" className="btn btn-default rounded-2 btn-lg shadow-sm">CREATE MATCH</button>}
                                    </div>
                                </div>
                            </form>
                        </>
                        }
                    </>
                    :
                    <></>
                    }
                </>
            }
            
        </div>
    );
}

export default Game;
