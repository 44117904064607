import React, { useEffect,useState } from 'react';

function Support() {
    const [token, setToken] = useState();

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            setToken(tokenJson.token);
        } else {
            setToken('');
        }
    }, []);

    return (
        <>
            <div>
                <div className="row mt-5 pt-5 mb-3">
                    <div className="col align-self-center">
                        <h6 className="title text-center">PACXOS SUPPORT</h6>
                        <p className="text-secondary small text-center">Get in touch with our support team.</p>
                    </div>
                    <div className="col-12 text-center mt-3">
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <a href="tel:+254718841871" className='d-flex align-items-center gap-2'>
                                    <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                        <i className="bi bi-telephone size-24" />
                                    </div>
                                    <div className='flex-grow-1 text-start'>
                                        <p className="mb-1 small">Call Us</p>
                                        <p className="mb-0 text-secondary size-16">+254 718 841 871</p>
                                    </div>
                                </a>
                            </div>
                            <div className='col-md-6 mb-4'>
                                <a href="tel:+254718841871" className='d-flex align-items-center gap-2'>
                                    <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                        <i className="bi bi-whatsapp size-24" />
                                    </div>
                                    <div className='flex-grow-1 text-start'>
                                        <p className="mb-1 small">Talk To Us</p>
                                        <p className="mb-0 text-secondary size-16">+254 718 841 871</p>
                                    </div>
                                </a>
                            </div>
                            <div className='col-md-6 mb-4'>
                                <a href="mailto:helpdesk@pacxos.com" className='d-flex align-items-center gap-2'>
                                    <div className="avatar avatar-70 bg-white text-color-theme mb-2 rounded-18 shadow-sm">
                                        <i className="bi bi-envelope size-24" />
                                    </div>
                                    <div className='flex-grow-1 text-start'>
                                        <p className="mb-1 small">Email Us</p>
                                        <p className="mb-0 text-secondary size-16">helpdesk@pacxos.com</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;